import About from "../components/about_page_about_section/About";
import Skills from "../components/skills_about_page/skills";

const About_page = () => {
 
  return <>
    <About />
    <Skills/>
  </>;
};
export default About_page;

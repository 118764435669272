
// import component

import Complete_apps from "../components/complete-apps/Complete_apps";
import Small_projects from "../components/small_projects/Small_projects";

const Project_page = () => {


  return (
    <>
      <Complete_apps />
      <Small_projects />
    </>
  );
};
export default Project_page;
